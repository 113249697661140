exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parents-and-patients-app-js": () => import("./../../../src/pages/parents-and-patients-app.js" /* webpackChunkName: "component---src-pages-parents-and-patients-app-js" */),
  "component---src-pages-platform-functions-js": () => import("./../../../src/pages/platform-functions.js" /* webpackChunkName: "component---src-pages-platform-functions-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-przedsprzedaz-checkout-js": () => import("./../../../src/pages/przedsprzedaz/checkout.js" /* webpackChunkName: "component---src-pages-przedsprzedaz-checkout-js" */),
  "component---src-pages-przedsprzedaz-index-js": () => import("./../../../src/pages/przedsprzedaz/index.js" /* webpackChunkName: "component---src-pages-przedsprzedaz-index-js" */),
  "component---src-pages-przedsprzedaz-podziekowanie-js": () => import("./../../../src/pages/przedsprzedaz/podziekowanie.js" /* webpackChunkName: "component---src-pages-przedsprzedaz-podziekowanie-js" */),
  "component---src-pages-regulamin-przedsprzedazy-js": () => import("./../../../src/pages/regulamin-przedsprzedazy.js" /* webpackChunkName: "component---src-pages-regulamin-przedsprzedazy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

